import { FunctionComponent } from 'react';
import { Space } from 'antd';
import Text from 'antd/lib/typography/Text';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
import { capitalize } from 'lodash';
import { InfoContainer, InfoParagraph } from '../TrialInfo/TrialInfo';
import { StyledTitle } from '../../styles/typography';
import { useLocaleCode } from '../../core/hooks';

import {
  StyledHeaderTrialName,
  StyledTag,
} from '../TrialHeaderSection/TrialHeaderSection';
import { ETrialStatus, trialStatusMap } from '../../types/trials';
import {
  AboutTrialCard,
  AboutTrialInfo,
  DatesContent,
  Description,
  InfoWrapper,
} from '../AboutTrialSection/AboutTrialSection';
import { Person } from '../icons/SVGs';
import { dateConvert } from '../../utils/dateConversion';

const AboutTrial = styled.div`
  ${({ theme }) => `
  margin-top: ${theme.spacing.superLarge};
`}
`;

const ParagraphsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => `
        gap: ${theme.spacing.mediumLarge};
  `}
`;
const TrialInfoAboutTrialWrapper = styled.div`
  ${({ theme }) => `
    margin-top: -${theme.spacing.superLarge};
    @media (max-width: ${theme.breakpoints.tablet}) {
      margin-top: 0;
    }
  `}
`;

export const TrialInfoAboutTrialSection: FunctionComponent = () => {
  const { t, i18n } = useTranslation();
  const fullLanguageCode = useLocaleCode();

  const phaseNumber = ['tr-TR'].includes(i18n.language) ? 3 : 4;
  return (
    <TrialInfoAboutTrialWrapper>
      <StyledHeaderTrialName>
        <Text style={{ fontSize: '1.5rem', color: '#696f83' }}>
          {t('common.pageTitle')}
        </Text>{' '}
        <StyledTag color={trialStatusMap.get(ETrialStatus.RECRUITING)}>
          {t(`common.trialStatusRecruiting`).toUpperCase()}
        </StyledTag>
      </StyledHeaderTrialName>
      <StyledTitle>{t('trialInformation.title')}</StyledTitle>
      <InfoContainer>
        <ParagraphsWrapper>
          <InfoParagraph>
            {t('trialInformation.content.part1', {
              alreadyApprovedText: t('common.alreadyApprovedText'),
              studyMedicationName: t('common.studyMedicationName'),
            })}
          </InfoParagraph>
          <InfoParagraph>
            {t('trialInformation.content.part2', {
              standardOfCareMedication1: t('common.standardOfCareMedication1'),
              standardOfCareMedication2: t('common.standardOfCareMedication2'),
              standardOfCareMedication3: t('common.standardOfCareMedication3'),
            })}
          </InfoParagraph>
          <InfoParagraph>{t('trialInformation.content.part3')}</InfoParagraph>
        </ParagraphsWrapper>
        <Space direction="vertical">
          <InfoParagraph className="ref-num">
            {t('trialInformation.refNum')}
          </InfoParagraph>
          <InfoParagraph strong>NCT05097287</InfoParagraph>
        </Space>
      </InfoContainer>
      <InfoWrapper>
        <AboutTrialInfo title={t('aboutTrial.phaseNumber', { phaseNumber })}>
          <p>
            <Trans
              i18nKey={
                phaseNumber === 3
                  ? 'aboutTrial.info.phase3Description'
                  : 'aboutTrial.info.phase4Description'
              }
              components={{ bold: <strong />, italic: <i /> }}
            />
          </p>
        </AboutTrialInfo>
        {fullLanguageCode[1] === 'en-US' ||
        fullLanguageCode[1] === 'es-US' ||
        fullLanguageCode[1] === 'fr-CA' ||
        fullLanguageCode[1] === 'en-CA' ||
        fullLanguageCode[1] === 'el-GR' ||
        fullLanguageCode[1] === 'sk-SK' ||
        fullLanguageCode[1] === 'hu-HU' ||
        fullLanguageCode[1] === 'ro-RO' ||
        fullLanguageCode[1] === 'en-ZA' ||
        fullLanguageCode[1] === 'af-ZA' ||
        fullLanguageCode[1] === 'bg-BG' ||
        fullLanguageCode[1] === 'tr-TR' ||
        fullLanguageCode[1] === 'pt-BR' ? (
          ''
        ) : (
          <AboutTrialInfo title={t('aboutTrial.info.datesText')}>
            <DatesContent>
              <div>
                <b>{t('aboutTrial.info.lastPatientFirstVisitDateLabel')}</b>
                <p>{capitalize(dateConvert(new Date(2024, 6, 1)))}</p>
              </div>
              <div>
                <b>{t('aboutTrial.info.studyCompletionDateLabel')}</b>
                <p>{capitalize(dateConvert(new Date(2027, 9, 1)))}</p>
              </div>
            </DatesContent>
          </AboutTrialInfo>
        )}
      </InfoWrapper>
      <AboutTrial>
        <StyledTitle $textCapitalize>{t('aboutTrial.title')}</StyledTitle>
        <Description>{t('aboutTrial.description')}</Description>

        <AboutTrialCard
          Icon={Person}
          ratio="2:1"
          cardTextFirst={
            <Trans
              i18nKey="aboutTrial.cardText.first"
              values={{ studyMedicationName: t('common.studyMedicationName') }}
              components={{ bold: <strong /> }}
            />
          }
          cardTextSecond={
            <Trans
              i18nKey="aboutTrial.cardText.second"
              components={{ bold: <strong /> }}
            />
          }
        />
      </AboutTrial>
    </TrialInfoAboutTrialWrapper>
  );
};
